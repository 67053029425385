<template>
  <div>
    <Modal
      v-show="showOrderModal"
      :show="showOrderModal"
      :scrollable="true"
      header-id="modalHeader"
      body-id="modalBody"
      @close="toggleShowModalOrder"
    >
      <template #header>
        Список позиций
      </template>
      <template #body v-if="this.isOpenedWindow">
        <order-info :order="selectedOrder">
        </order-info>
      </template>
    </Modal>
    <base-table
      :render-list-to-show="listToShow"
      :per-page="50"
      :wrapper-filters-data="wrapperFiltersData"
      :columns="columns"
      :table-title="'Заказы'"
    >
      <template v-slot:base-table-row="props">
        <div @click="toggleShowModalOrder(props.row)" style="cursor: pointer">
          <template v-if="props.column.field === 'type'">
            {{
              props.formattedRow[props.column.field]['description'] + ' (' + props.formattedRow[props.column.field]['id'] + ')'
            }}
          </template>
          <template v-else>
            {{ props.formattedRow[props.column.field] }}
          </template>
        </div>
      </template>
    </base-table>
  </div>
</template>

<script>
import Modal from '../../../components/table/modal.vue';
import BaseAdminMixin from "../../../components/mixins/BaseAdminMixin.vue";
import Multiselect from "vue-multiselect";
import BaseTable from "../../../components/table/baseTable";
import ModalUpdate from "../../../components/table/modalUpdate.vue";

export default {
  name: "ordersList",
  components: {
    ModalUpdate,
    Multiselect,
    BaseAdminMixin,
    Modal,
    BaseTable
  },
  mixins: [
    BaseAdminMixin,
  ],
  props: [
    'user'
  ],
  data: function () {
    return {
      selectedId: '',
      selectedOrder: null,
      showOrderModal: false,
      isOpenedWindow: false,
      columns: [
        {
          label: 'ID',
          field: 'id',
          type: 'number',
          width: '6%',
          sortable: true,
        },
        {
          label: 'Заведение',
          field: 'restaurant',
          width: '15%',
          sortable: false,
        },
        {
          label: 'Клиент',
          field: 'user',
          width: '15%',
          sortable: false,
        },
        {
          label: 'Статус',
          field: 'status',
          width: '10%',
          sortable: false,
        },
        {
          label: 'Описание',
          field: 'status_description',
          width: '10%',
          sortable: false,
        },
        {
          label: 'Доставка',
          field: 'delivery_type',
          width: '15%',
          sortable: false,
        },
        {
          label: 'Оплата',
          field: 'payment_type',
          width: '15%',
          sortable: false,
        },
        {
          label: 'Сумма',
          field: 'sum',
          width: '10%',
          type: 'number',
          sortable: true,
        },
        {
          label: 'Дата',
          field: 'created_at',
          width: '12%',
          sortable: true,
        },
      ],
    }
  },
  methods: {
    listToShow: function (list) {
      let data = [];
      for (let elem of list) {
        data.push({
          id: elem['id'],
          user: elem['user']['phone'] + ' (' + elem['user']['email'] + ')',
          restaurant: elem['restaurant']['title'] + ' (' + elem['restaurant']['id'] + ')',
          status: elem['status']['description'] + ' (' + elem['status']['id'] + ')',
          status_id: elem['status']['id'],
          payment_type: elem['payment_type']['description'] + ' (' + elem['payment_type']['id'] + ')',
          delivery_type: elem['delivery_type']['description'] + ' (' + elem['delivery_type']['id'] + ')',
          status_description: elem['status_description'],
          sum: elem['sum'],
          commission: elem['commission'],
          created_at: elem['created_at'],
          updated_at: elem['updated_at'],
        });
      }
      return data;
    },
    toggleShowModalOrder: function (order) {
      this.selectedOrder = order;
      this.showOrderModal = !this.showOrderModal;
      this.isOpenedWindow = !this.isOpenedWindow;
    },
  },
  computed: {
    wrapperFiltersData() {
      return {
        title: "Заказы",
        filtersUrl: "/order/getList",
        filtersUrlMethod: "get",
        startWithRequest: true,
        items: {
          date: {
            enable: true,
            column: 1,
          },
          dateSelector: {
            enable: true,
            placeholder: 'Период',
            noGroupSelect: true,
            closeOnSelect: true,
            selectedKey: 'dateSelector',
            data: [
              {
                id: 3,
                title: 'За последний день'
              },
              {
                id: 4,
                title: 'За последние 7 дней'
              },
              {
                id: 5,
                title: 'За последние 30 дней'
              },
            ],
            column: 2,
            defaultValue: 3,
            setDefaultInFilters: 3
          },
          id: {
            simpleInputType: 'text',
            placeholder: 'ID заказа',
            defaultValue: '',
            selectedKey: 'orderId',
            column: 3,
          },
          status: {
            multiple: true,
            allowEmpty: true,
            closeOnSelect: false,
            noGroupSelect: false,
            getDataLink: '/order/getOrderStatuses',
            specifyKeyForLabel: 'description',
            placeholder: 'Статус',
            viewLabel: 'titleWithId',
            selectedKey: 'statuses',
            column: 1,
          },
          delivery_type: {
            multiple: true,
            allowEmpty: true,
            closeOnSelect: false,
            noGroupSelect: false,
            getDataLink: '/order/getOrderDeliveryTypes',
            specifyKeyForLabel: 'description',
            placeholder: 'Тип доставки',
            viewLabel: 'titleWithId',
            selectedKey: 'deliveryTypes',
            column: 2,
          },
          payment_type: {
            multiple: true,
            allowEmpty: true,
            closeOnSelect: false,
            noGroupSelect: false,
            getDataLink: '/order/getOrderPaymentTypes',
            specifyKeyForLabel: 'description',
            placeholder: 'Тип оплаты',
            viewLabel: 'titleWithId',
            selectedKey: 'paymentTypes',
            column: 3,
          },
        }
      }
    },
  }
}
</script>
